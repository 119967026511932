import axios, { AxiosInstance } from 'axios'
import axiosRetry from 'axios-retry'

import { url } from '@octadesk-tech/services'

import { STATUS_CODE } from '@/common/helpers/enums/http-status-codes'

import store from '@/store'

const API_CLIENTS = new Map<string, AxiosInstance>()

export const createClient = (options = {}) => axios.create(options)

export const getClient = async (api: string): Promise<AxiosInstance> => {
  if (API_CLIENTS.has(api)) {
    return API_CLIENTS.get(api) as AxiosInstance
  }

  const baseURL = await url.getAPIURL(api)

  if (!baseURL || !baseURL.length) {
    throw new Error(`URL for api ${api} not found`)
  }

  const client = createClient({ baseURL })

  client.interceptors.response.use(
    response => response,
    error => {
      if (
        error.response?.status === STATUS_CODE.UNAUTHORIZED ||
        error.response?.status === STATUS_CODE.FORBIDDEN
      ) {
        store.dispatch('logout', {
          logoutReason: 'expiredSession'
        })
      }
    }
  )

  axiosRetry(client, { retries: 3 })

  API_CLIENTS.set(api, client)

  return API_CLIENTS.get(api) as AxiosInstance
}

export default createClient()
