import axiosRetry from 'axios-retry'

import { services } from '@octadesk-tech/services'

import { STATUS_CODE } from '@/common/helpers/enums/http-status-codes'

import store from '@/store'

const API_CLIENTS = new Map()

export const getBaseClient = async (api, options = {}) => {
  if (API_CLIENTS.has(api)) {
    return API_CLIENTS.get(api)
  }

  const client = await services.createClient(api, options)

  axiosRetry(client, {
    retries: 5,
    retryDelay: retryCount => {
      return retryCount * 1000
    }
  })

  client.interceptors.response.use(
    response => response,
    error => {
      if (
        error.response?.status === STATUS_CODE.UNAUTHORIZED ||
        error.response?.status === STATUS_CODE.FORBIDDEN
      ) {
        store.dispatch('logout', {
          logoutReason: 'expiredSession'
        })
      }
    }
  )

  API_CLIENTS.set(api, client)

  return client
}

let _chatClient
export const getChatClient = async (options = {}) => {
  if (_chatClient) {
    return _chatClient
  }
  return (_chatClient = await services.chat.getClient(options))
}

export const createClient = async (apiName, options = {}) => {
  if (API_CLIENTS.has(apiName)) {
    return API_CLIENTS.get(apiName)
  }
  API_CLIENTS.set(apiName, await services.createClient(apiName, options))
  return API_CLIENTS.get(apiName)
}
