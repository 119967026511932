import { BulkMessageState } from './interfaces'

const state: BulkMessageState = {
  bulkMessagesModalContent: undefined,
  bulkMessagesModalIsOpen: false,
  whatsappOfficialNumbers: [],
  bulkMessagesConfirm: false,
  approvedTemplateMessages: [],
  bulkMessages: [],
  selectedBulkMessage: false,
  bulkMessagesReport: '',
  availableTemplateMessages: 0,
  retrievedTemplateMessage: undefined,
  bulkMessagesNumber: 0,
  chatIntegrators: [],
  bulkMessagesData: '',
  currentListPage: 1,
  currentListLoader: false,
  totalBulkMessages: 0,
  isExternalTemplateChecked: false,
  isExternalTemplateValid: true
}

export default state
