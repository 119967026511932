import { services, url } from '@octadesk-tech/services'

import { getClient as getBaseClient } from '@/common/services/http'
import legacyHeaders from '@/common/services/legacy-headers'

import { loadParameterHeader } from './headers'

export class MessagesService {
  private static instance: MessagesService

  public static getInstance(): MessagesService {
    if (!this.instance) {
      this.instance = new MessagesService()
    }

    return this.instance
  }

  private _clients: any = {}

  async getChatClient() {
    if (this._clients && this._clients['chat']) {
      return this._clients['chat']
    }

    if (!services['chat']) {
      throw new Error('Cannot get http client: missing service chat')
    }

    this._clients['chat'] = await services['chat'].getClient(legacyHeaders())

    return this._clients['chat']
  }

  async retrieveApprovedTemplateMessages(payload?: { name: string }) {
    return this.getChatClient().then(client =>
      client.post(
        '/quick-reply/filter',
        {
          ...payload,
          type: 'template',
          fullResponse: true,
          filter: {
            type: 'template',
            status: 'approved'
          },
          sort: {
            createdAt: -1
          }
        },
        loadParameterHeader()
      )
    )
  }

  async retrieveBulkMessages({
    search = '',
    status = '',
    page
  }: {
    search?: string
    status?: string
    page: number
  }) {
    const { data } = await this.getChatClient().then(client =>
      client.post(
        `/bulk-messagings/filter/?search=${search}&status=${status}`,
        {
          page,
          size: 25,
          sort: {
            'created.at': -1
          }
        },
        loadParameterHeader()
      )
    )

    return data
  }

  async retrieveBulkMessageReport(id: string) {
    const { data } = await this.getChatClient().then(client =>
      client.post(
        `/bulk-messagings/${id}/reports/spreadsheet`,
        undefined,
        loadParameterHeader()
      )
    )

    return data
  }

  async getSpreadsheetImporterUrl() {
    return await url.getAPIURL('spreadsheetImporter')
  }

  async retrieveTemplateMessage(id: string) {
    const { data } = await this.getChatClient().then(client =>
      client.get(`/quick-reply/${id}`, loadParameterHeader())
    )
    return data
  }

  async startBulkMessage(id: string, payload?: any) {
    if (!id) return

    const { data } = await this.getChatClient().then(client =>
      client.post(
        `/bulk-messagings/${id}/start`,
        payload,
        loadParameterHeader()
      )
    )

    return data
  }

  async createBulkMessages(payload: any) {
    const { data } = await this.getChatClient().then(client =>
      client.post('/bulk-messagings', payload, loadParameterHeader())
    )

    return data
  }

  async cancelBulkMessage(id: string, payload: any) {
    const { data } = await this.getChatClient().then(client =>
      client.post(
        `/bulk-messagings/${id}/cancel`,
        payload,
        loadParameterHeader()
      )
    )

    return data
  }

  async responseUploadFile(data: any) {
    try {
      const response = await getBaseClient('spreadsheetImporter').then(client =>
        client
          .post(
            `/api/importer/validate-spreadsheet?subdomain=${data.subdomain}&culture=pt-br`,
            data.body,
            loadParameterHeader()
          )
          .then(res => res.data)
          .then(data.success)
          .catch(data.error)
      )

      if (response) {
        return response
      }

      return false
    } catch (ex) {
      console.error(ex)
    }
  }

  getHasApprovedMessages = (): Promise<boolean> =>
    this.getChatClient()
      .then(client =>
        client.get('/quick-reply/status/has-approved', loadParameterHeader())
      )
      .then(response => response.data)

  async checkExternalTemplateMessage(
    quickReplyId: string,
    externalAccountId: string
  ): Promise<void> {
    const client = await this.getChatClient()

    await client.get(
      `/quick-replies/${quickReplyId}/external-accounts/${externalAccountId}/check-external`,
      loadParameterHeader()
    )
  }
}
