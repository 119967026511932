export const SET_BULK_MESSAGES_MODAL_CONTENT = 'SET_BULK_MESSAGES_MODAL_CONTENT'

export const SET_BULK_MESSAGES_MODAL_IS_OPEN = 'SET_BULK_MESSAGES_MODAL_IS_OPEN'

export const SET_WHATSAPP_OFFICIAL_NUMBERS = 'SET_WHATSAPP_OFFICIAL_NUMBERS'

export const SET_APPROVED_TEMPLATE_MESSAGES = 'SET_APPROVED_TEMPLATE_MESSAGES'

export const SET_BULK_MESSAGES = 'SET_BULK_MESSAGES'

export const SET_SELECTED_BULK_MESSAGE = 'SET_SELECTED_BULK_MESSAGE'

export const SET_BULK_MESSAGES_REPORT = 'SET_BULK_MESSAGES_REPORT'

export const SET_BULK_MESSAGES_CONFIRM = 'SET_BULK_MESSAGES_CONFIRM'

export const SET_RETRIEVED_TEMPLATE_MESSAGE = 'SET_RETRIEVED_TEMPLATE_MESSAGE'

export const SET_CHAT_INTEGRATORS = 'SET_CHAT_INTEGRATORS'

export const SET_BULK_MESSAGES_NUMBER = 'SET_BULK_MESSAGES_NUMBER'

export const SET_BULK_MESSAGES_DATA = 'SET_BULK_MESSAGES_DATA'

export const SET_CURRENT_LIST_PAGE = 'SET_CURRENT_LIST_PAGE'

export const SET_TOTAL_BULK_MESSAGES = 'SET_TOTAL_BULK_MESSAGES'

export const SET_CURRENT_LIST_LOADER = 'SET_CURRENT_LIST_LOADER'

export const CHECK_EXTERNAL_TEMPLATE_MESSAGE_STARTED =
  'CHECK_EXTERNAL_TEMPLATE_MESSAGE_STARTED'

export const CHECK_EXTERNAL_TEMPLATE_MESSAGE_FINISED =
  'CHECK_EXTERNAL_TEMPLATE_MESSAGE_FINISED'
