import { InboxGroup } from '@/store/interfaces'

import { getClient } from './http'
import legacyHeaders from './legacy-headers'
const API_NAME = 'chatUrl'

export class GroupsService {
  getClient() {
    return getClient(API_NAME)
  }

  async get() {
    const { data } = await this.getClient().then(client =>
      client.get('/inbox/manage/groups', legacyHeaders())
    )

    return data
  }

  async findGroupsByUser(id: string) {
    const { data } = await this.getClient().then(client =>
      client.get(`/groups/agent/${id}`, legacyHeaders())
    )

    return data
  }
  async update(groups: any[]) {
    try {
      const { data } = await this.getClient().then(client =>
        client.put(`/inbox/manage/groups`, groups, legacyHeaders())
      )

      return data
    } catch (ex) {
      if (ex) {
        throw new Error('Error at updateGroup: ' + ex)
      }
    }
  }

  async saveGroupsByUser(id: string, groups: any[]) {
    const { data } = await this.getClient().then(client =>
      client.post(
        `/groups/agent/saveGroups/${id}`,
        { groups: groups },
        legacyHeaders()
      )
    )

    return data
  }

  async getInboxGroups() {
    try {
      const client = await this.getClient()
      return await client.get<{ groups: InboxGroup[] }>(
        `/rooms/inbox/groups`,
        legacyHeaders()
      )
    } catch (ex) {
      throw new Error('Error at getInboxGroups: ' + ex)
    }
  }
}
