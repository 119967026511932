export const MessagesTypes = {
  Macro: 'macro',
  Template: 'template'
}

export const VariablesTypes = {
  Text: 'text',
  Location: 'location',
  Image: 'image'
}

export const LocationFields = {
  Name: 'name',
  ZipCode: 'zipCode',
  Address: 'address',
  Number: 'number',
  Neighborhood: 'neighborhood',
  State: 'state',
  City: 'city',
  Complement: 'complement',
  Latitude: 'latitude',
  Longitude: 'longitude'
}
