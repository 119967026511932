import { headers } from '@octadesk-tech/services'

import { getChatClient } from '@/modules/Chat/services/http'

export const recordSavedMessages = payload =>
  getChatClient().then(client =>
    client.post('/quick-reply/filter', payload, headers.getAuthorizedHeaders())
  )

export const recordQuickMessage = id =>
  getChatClient().then(client =>
    client.get(`/macros/${id}`, headers.getAuthorizedHeaders())
  )

export const isValidExternalTemplate = async (
  quickReplyId,
  externalAccountId
) => {
  try {
    const client = await getChatClient()

    await client.get(
      `/quick-replies/${quickReplyId}/external-accounts/${externalAccountId}/check-external`,
      headers.getAuthorizedHeaders()
    )

    return true
  } catch {
    return false
  }
}
