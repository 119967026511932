import { ActionContext } from 'vuex'

import dayjs from 'dayjs'

import {
  FormSteps,
  FormTypes,
  SendTypes
} from '@/modules/ChatSettings/whatsapp-oficial/bulk-messages/v2/bulk-messages.enum'
import { MessagesService } from '@/modules/ChatSettings/whatsapp-oficial/bulk-messages/v2/services/messages.service'
import { WhatsAppIntegratorService } from '@/modules/ChatSettings/whatsapp-oficial/bulk-messages/v2/services/whatsapp-integrator.service'

import { RootState } from '@/store/interfaces'

import { BulkMessageState, FormPayload, SuccessModal } from './interfaces'
import * as types from './mutations-types'
import { BULK_MESSAGE_FORM_PAYLOAD_INITIAL_DATA } from './state'

type CheckExternalTemplate = {
  quickReplyId: string
  externalAccountId: string
}

const whatsAppIntegratorService = WhatsAppIntegratorService.getInstance()

const messageIntegratorService = MessagesService.getInstance()

export const loadInitialData = (
  context: ActionContext<BulkMessageState, RootState>,
  { isFormPage, isEditForm }: { isFormPage: boolean; isEditForm: FormTypes }
) => {
  context.commit(types.SET_INITIAL_DATA_LOADER, true)

  context.commit(types.SET_INITIAL_DATA_ERROR, false)

  const actionsList = [
    context.dispatch('getWhatsappNumbers'),
    context.dispatch('retrieveBulkMessages', { page: 1 })
  ]

  if (isFormPage) {
    actionsList.push(context.dispatch('fetchGroups', {}, { root: true }))

    actionsList.push(context.dispatch('fetchAgents', {}, { root: true }))
  }

  if (!isEditForm) {
    actionsList.push(context.dispatch('getApprovedTemplateMessages'))
  }

  Promise.all(actionsList)
    .catch(() => {
      context.commit(types.SET_INITIAL_DATA_ERROR, true)
    })
    .finally(() => {
      context.commit(types.SET_INITIAL_DATA_LOADER, false)
    })
}

export const setSelectedBulkMessage = (
  context: ActionContext<BulkMessageState, RootState>,
  value: any
) => {
  context.commit(types.SET_SELECTED_BULK_MESSAGE, value)
}

export const getWhatsappNumbers = async (
  context: ActionContext<BulkMessageState, RootState>
) => {
  const whatsappOfficialNumbers =
    await whatsAppIntegratorService.getIntegratorWhatsappNumbers()

  context.commit(types.SET_WHATSAPP_OFFICIAL_NUMBERS, whatsappOfficialNumbers)

  return whatsappOfficialNumbers
}

export const getApprovedTemplateMessages = async (
  context: ActionContext<BulkMessageState, RootState>
) => {
  const { formPayload } = context.getters

  const { data: approvedTemplateMessages } =
    await messageIntegratorService.retrieveApprovedTemplateMessages({
      phoneNumber: formPayload.origin.from
    })

  context.commit(types.SET_APPROVED_TEMPLATE_MESSAGES, approvedTemplateMessages)

  return approvedTemplateMessages
}

export const retrieveBulkMessageReport = async (
  context: ActionContext<BulkMessageState, RootState>,
  id: string
) => {
  const bulkMessagesReport =
    await messageIntegratorService.retrieveBulkMessageReport(id)

  context.commit(types.SET_BULK_MESSAGES_REPORT, bulkMessagesReport)

  return bulkMessagesReport
}

export const retrieveBulkMessages = async (
  context: ActionContext<BulkMessageState, RootState>,
  { search, status, page }: { search: string; status: string; page: number }
) => {
  context.commit(types.SET_CURRENT_LIST_LOADER, true)

  const { bulkMessages } = context.getters

  const currentBulkMessages = bulkMessages

  context.commit(types.SET_BULK_MESSAGES, [])

  const { items: bulkMessagesList, total } =
    await messageIntegratorService.retrieveBulkMessages({
      search,
      status,
      page
    })

  context.commit(types.SET_CURRENT_LIST_PAGE, page)

  context.commit(types.SET_TOTAL_BULK_MESSAGES, total)

  const payload =
    page === 1
      ? bulkMessagesList
      : [...currentBulkMessages, ...bulkMessagesList]

  context.commit(types.SET_BULK_MESSAGES, payload)

  context.commit(types.SET_CURRENT_LIST_LOADER, false)
}

export const retrieveTemplateMessage = async (
  context: ActionContext<BulkMessageState, RootState>,
  id: any
) => {
  const retrievedTemplateMessage =
    await messageIntegratorService.retrieveTemplateMessage(id)

  context.commit(types.SET_RETRIEVED_TEMPLATE_MESSAGE, retrievedTemplateMessage)

  return retrievedTemplateMessage
}

export const setStep = (
  context: ActionContext<BulkMessageState, RootState>,
  step: FormSteps
) => {
  context.commit(types.SET_TEMPLATE_MESSAGE_STEP, step)
}

export const setInitialFormPayload = (
  context: ActionContext<BulkMessageState, RootState>,
  initialFormPayload: FormPayload
) => {
  context.commit(
    types.SET_BULK_MESSAGE_INITIAL_FORM_PAYLOAD,
    initialFormPayload
  )
}

export const setFormPayload = (
  context: ActionContext<BulkMessageState, RootState>,
  formPayload: FormPayload
) => {
  context.commit(types.SET_BULK_MESSAGE_FORM_PAYLOAD, formPayload)
}

export const setSpreadSheetEmpty = (
  context: ActionContext<BulkMessageState, RootState>,
  formPayload: FormPayload
) => {
  context.commit(types.SET_IS_SPREAD_SHEET_EMPTY, formPayload)
}

export const setShowReview = (
  context: ActionContext<BulkMessageState, RootState>,
  showReview: boolean
) => {
  context.commit(types.SET_TEMPLATE_MESSAGE_SHOW_REVIEW, showReview)
}

export const setSuccessModal = (
  context: ActionContext<BulkMessageState, RootState>,
  payload: SuccessModal
) => {
  context.commit(types.SET_TEMPLATE_MESSAGE_SUCCESS_MODAL, payload)
}

export const setShowDiscardContentAlert = (
  context: ActionContext<BulkMessageState, RootState>,
  showAlert: boolean
) => {
  context.commit(types.SET_TEMPLATE_BULK_SHOW_DISCARD_CONTENT_ALERT, showAlert)
}

export const resetFormData = (
  context: ActionContext<BulkMessageState, RootState>
) => {
  context.dispatch('setFormPayload', BULK_MESSAGE_FORM_PAYLOAD_INITIAL_DATA)

  context.dispatch('setInitialFormPayload')

  context.dispatch('setStep', FormSteps.BasicInformations)
}

export const getHasApprovedMessages = async () =>
  messageIntegratorService.getHasApprovedMessages()

export const setCurrentBulkMessage = async (
  context: ActionContext<BulkMessageState, RootState>,
  id: string
) => {
  try {
    await context.dispatch('getApprovedTemplateMessages')

    const bulkMessage = await messageIntegratorService.getTemplateMessageById(
      id
    )

    const { approvedTemplateMessages } = context.getters

    const { name, origin, content, target } = bulkMessage

    const quickReply = approvedTemplateMessages.find(
      (item: { id: string }) => item.id === content?.quickReply?.id
    )

    let startAt = {
      type: SendTypes.Immediate,
      date: '',
      time: ''
    }

    if (bulkMessage?.schedule?.startAt) {
      const date = dayjs(bulkMessage.schedule.startAt).format('YYYY-MM-DD')

      const time = dayjs(bulkMessage.schedule.startAt).format('HH:mm')

      startAt = {
        type: SendTypes.Scheduled,
        date,
        time
      }
    }

    // @ts-ignore
    const payload: FormPayload = {
      name,
      origin,
      content: {
        ...content,
        quickReply
      },
      target,
      startAt
    }

    context.dispatch('setInitialFormPayload', payload)

    context.dispatch('setFormPayload', payload)
  } catch (err) {
    throw new Error('Error on setCurrentBulkMessage: ' + err)
  }
}

export const checkExternalTemplate = async (
  context: ActionContext<BulkMessageState, RootState>,
  { quickReplyId, externalAccountId }: CheckExternalTemplate
) => {
  context.commit(types.CHECK_EXTERNAL_TEMPLATE_MESSAGE_STARTED)

  try {
    await messageIntegratorService.checkExternalTemplateMessage(
      quickReplyId,
      externalAccountId
    )

    context.commit(types.CHECK_EXTERNAL_TEMPLATE_MESSAGE_FINISHED, true)
  } catch {
    context.commit(types.CHECK_EXTERNAL_TEMPLATE_MESSAGE_FINISHED, false)
  }
}
